
import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import { entries } from 'lodash'
import { Watch } from 'vue-property-decorator'
import { mixin } from '~/mixins/global'
import {
  BookingOutput,
  Reservation,
  ModalItem,
  Overlay,
  User,
} from '~/store/account/accountDetails'
import Modal from '~/components/Modal.vue'
import Btn from '~/components/Btn.vue'

@Component({
  components: {
    Modal,
    Btn,
  },
  computed: {
    ...mapState('account/accountDetails', [
      'user',
      'token',
      'processNotifications',
      'loadingNotifications',
      'bookingListFull',
      'overlay',
    ]),
  },
})
export default class AppHeader extends Vue {
  readonly bookingListFull!: BookingOutput[]
  readonly processNotifications!: boolean
  readonly loadingNotifications!: boolean
  readonly overlay!: Overlay
  readonly token!: string
  readonly user?: User

  showNotifications: boolean = false
  bookingFullStatus: ModalItem[] = []
  bookingData: BookingOutput[] = []

  notifications: number = 0

  toggleModal() {
    this.showNotifications = !this.showNotifications
    if (this.showNotifications) {
      this.$store.commit('account/accountDetails/updateOverlay', {
        overlayStatus: true,
        overlayType: 'modal',
      })
    }
  }

  processBookingNotifications() {
    this.bookingFullStatus = []
    entries(this.bookingListFull).forEach((entry: any) => {
      const [, value] = entry
      if (value.status && value.status === 'CONFIRMED') {
        entries(value.passengers).forEach((subEntry: any) => {
          const [, subValue] = subEntry
          const idx = this.bookingData?.findIndex((b) => b.id === value.id)
          const validate = mixin.validatePassenger(subValue)
          if (validate && validate.length > 0 && idx === -1) {
            this.bookingData.push(value)
          }
        })
      }
    })
    this.processBookingStatus()
  }

  processBookingStatus() {
    const hasBookingData = this.bookingData && this.bookingData.length > 0
    entries(this.bookingData).forEach((entry: any) => {
      const [, value] = entry
      const serviceStart =
        hasBookingData && this.mainProduct(value)
          ? this.mainProduct(value)?.[0]?.item?.serviceStart
          : null
      const serviceEnd =
        hasBookingData && this.mainProduct(value)
          ? this.mainProduct(value)?.[0]?.item?.serviceEnd
          : null
      const productName =
        hasBookingData && this.mainProduct(value)
          ? this.mainProduct(value)?.[0]?.item?.product?.groupName
          : ''
      if (
        this.mainProduct(value)
          ? this.mainProduct(value)?.[0]?.item?.status === 'CONFIRMED'
          : null
      ) {
        this.bookingFullStatus.push({
          id: value.id,
          title: productName || value.id,
          start: serviceStart,
          end: serviceEnd,
          ref: value.reference,
        })
      }
    })
    if (this.bookingFullStatus.length === 0) {
      this.bookingFullStatus.push({
        id: 'no-results',
        title: 'You currently have no notifications.',
      })
    }
  }

  mainProduct(booking: BookingOutput) {
    const statusPriority: { [key: string]: number } = {
      confirmed: 1,
      pending: 2,
      cancelled: 3,
      archived: 4,
    }

    if (
      !booking ||
      !booking.productReservations ||
      !Array.isArray(booking.productReservations)
    ) {
      console.log('Invalid booking or product reservations')
      return null
    }

    const mainProduct = booking.productReservations
      .filter(
        (item: Reservation) =>
          item?.price?.amount >= 0 &&
          item?.product?.provider &&
          item?.product?.externalId &&
          item?.status === 'CONFIRMED'
      )
      .map((item: Reservation) => {
        return {
          item,
        }
      })
      .sort((a: any, b: any) => {
        // Sort by status first
        const priorityA =
          statusPriority[a?.item?.status?.toLowerCase()] || Infinity
        const priorityB =
          statusPriority[b?.item?.status?.toLowerCase()] || Infinity

        if (priorityA !== priorityB) {
          return priorityA - priorityB
        }

        // Sort by price (descending order)
        return (b?.item?.price?.amount || 0) - (a?.item?.price?.amount || 0)
      })

    if (mainProduct?.length === 0) {
      console.log('No valid main product found')
      return null
    }

    return mainProduct
  }

  logout() {
    this.$store.commit('account/accountDetails/resetState')
    this.$store.commit('booking/paymentProcessing/resetState')
    this.$store.commit('booking/productDetails/resetState')
    this.$store.commit('booking/passengers/resetState')
    this.$store.commit('updateSku', null)
  }

  mounted() {
    this.processBookingNotifications()
    this.notifications =
      this.bookingFullStatus &&
      this.bookingFullStatus.length &&
      this.bookingFullStatus[0].id !== 'no-results'
        ? this.bookingFullStatus.length
        : 0
  }

  @Watch('processNotifications')
  onNotificationProcessing() {
    if (this.processNotifications) {
      this.processBookingNotifications()
      this.notifications =
        this.bookingFullStatus &&
        this.bookingFullStatus.length &&
        this.bookingFullStatus[0].id !== 'no-results'
          ? this.bookingFullStatus.length
          : 0
    }
  }

  @Watch('overlay')
  onOverlayChange() {
    if (!this.overlay.status) {
      this.showNotifications = false
    }
  }
}
