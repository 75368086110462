import axios from 'axios'
import pMap from 'p-map'
import { entries } from 'lodash'
// eslint-disable-next-line import/named
import { MutationTree } from 'vuex'
import NuxtRuntimeConfig = global.NuxtRuntimeConfig

export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Timestamp: any
}
export interface SummaryLineItem {
  id?: Scalars['ID']
  text: Scalars['String']
  detail: any
}

export interface ModalItem {
  id: Scalars['String']
  title: Scalars['String']
  start?: Scalars['String']
  end?: Scalars['String']
  ref?: Scalars['String']
}

export interface FileItem {
  contentType: Scalars['String']
  createdAt: Scalars['Timestamp']
  id: Scalars['String']
  size: Scalars['Int']
  title: Scalars['String']
  updatedAt: Scalars['Timestamp']
  url: Scalars['String']
}

export interface NoteItem {
  content: Scalars['String']
  createdAt: Scalars['Timestamp'] | null
  id: Scalars['String'] | null
  title: Scalars['String'] | null
  updatedAt: Scalars['Timestamp'] | null
  updateExisting?: Scalars['Boolean']
  // Optional items.
  //
  label?: Scalars['String']
  useLabel?: Scalars['Boolean']
  heading?: Scalars['String']
  isRequired?: Scalars['Boolean']
  type?: Scalars['String']
  opts?: any[]
}

export interface FileCard {
  name: Scalars['String'] | null
  path: Scalars['String'] | null
}

export type ProductPaymentOpts = {
  id: Scalars['ID']
  monthly?: Scalars['Boolean']
  available: Scalars['Int']
}

export type ResProduct = {
  id: Scalars['ID']
  name: Scalars['String']
  groupName?: Scalars['String']
  description: Scalars['String']
  externalId: Scalars['String']
  provider: {
    id: Scalars['String']
  }
}
export type ProviderProduct = {
  id: Scalars['ID']
  description: Scalars['String']
  element: Scalars['String']
  groupName: Scalars['String']
  serviceDates: {
    start: Scalars['Timestamp']
    end: Scalars['Timestamp']
    kind: Scalars['String']
  }
  pricing: {
    deposit: {
      amount: Scalars['Int']
      currency: Scalars['String']
    }
    price: {
      amount: Scalars['Int']
      currency: Scalars['String']
    }
    balanceDue: Scalars['Timestamp']
  }
  occupancy: {
    minimum: Scalars['Int']
    maximum: Scalars['Int']
  }
  inventory: {
    type: Scalars['String']
    available: Scalars['Int']
  }
  relatedProduct: any[]
  extended: any[]
}

export type Pax = {
  id?: Scalars['ID'] | null
  name?: Scalars['String'] | null
  firstName?: Scalars['String'] | null
  lastName?: Scalars['String'] | null
  email: Scalars['ID'] | null
  dateOfBirth?: Scalars['Timestamp']
  sex?: Scalars['ID'] | null
  telephone: Scalars['String'] | null
  customer?: {
    id?: Scalars['ID'] | null
    firstName?: Scalars['String'] | null
    lastName?: Scalars['String'] | null
  }
  files?: FileItem[] | null
  notes?: NoteItem[] | null
  passport?: {
    number?: Scalars['String'] | null
    issuedAt?: Scalars['Timestamp']
    expiresAt?: Scalars['Timestamp']
    placeOfIssue?: Scalars['String'] | null
    countryCode?: Scalars['String'] | null
  }
}

export type Reservation = {
  id: Scalars['ID']
  reference: Scalars['String']
  status: Scalars['String']
  price: {
    amount: Scalars['Int']
    currency: Scalars['String']
  }
  passengers: string[]
  serviceStart: Scalars['Timestamp']
  serviceEnd: Scalars['Timestamp']
  product: ResProduct
}

export type BookingInput = {
  id: Scalars['ID']
  reference: Scalars['String']
}

export type BookingTransaction = {
  category: Scalars['String']
  status: Scalars['String']
  reference: Scalars['String']
  createdAt: Scalars['Timestamp']
  updatedAt: Scalars['Timestamp']
  amount: {
    amount: Scalars['Int']
    currency: Scalars['String']
  }
}

export type BookingContract = {
  amount: Scalars['Int']
  currency: Scalars['String']
  frequency: Scalars['String']
  id: Scalars['String']
  chargeCount: Scalars['Int']
  nextCharge: Scalars['String']
  occurrences: Scalars['Int']
  status: Scalars['String']
}

export type BookingOutput = {
  contract?: BookingContract
  id: Scalars['ID']
  description: Scalars['String']
  reference: Scalars['String']
  status: Scalars['String']
  totalPrice: {
    amount: Scalars['Int']
    currency: Scalars['String']
  }
  outstandingBalance: {
    amount: Scalars['Int']
    currency: Scalars['String']
  }
  productReservations: Reservation[]
  passengers: Pax[]
  customer: {
    id: Scalars['String']
  }
  transactions: BookingTransaction[]
}

export type User = {
  id: Scalars['ID']
  organizationId: Scalars['String']
  customerId: Scalars['String']
  name?: Scalars['String']
  firstName?: Scalars['String']
  lastName?: Scalars['String']
  email: Scalars['String']
}

export type Address = {
  line1: Scalars['String']
  line2: Scalars['String']
  line3: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
  country: Scalars['String']
  postalCode: Scalars['String']
}

export type Customer = {
  id: Scalars['ID']
  firstName: Scalars['String']
  lastName: Scalars['String']
  knownAs: Scalars['String']
  email: Scalars['String']
  telephone: Scalars['String']
  organization: {
    id: Scalars['String']
    name: Scalars['String']
  }
  address: Address
  bookings: BookingInput[] | []
}

export type Overlay = {
  status: Scalars['Boolean']
  type: Scalars['String']
}

export interface AccountState {
  loginStatus: boolean
  user: User | null
  customer: Customer | null
  token: string
  activeBooking: BookingOutput | null
  activeProduct: ProviderProduct | null
  activePaymentOpts: ProductPaymentOpts[] | null
  activePaymentType: string
  bookingList: any | null
  bookingListFull: BookingOutput[] | null
  contractProcessing: boolean
  processNotifications: boolean
  loadingNotifications: boolean
  bookingTab: string
  overlay: Overlay
}

export const state = (): AccountState => ({
  loginStatus: false,
  user: null,
  customer: null,
  activeBooking: null,
  activeProduct: null,
  activePaymentOpts: null,
  activePaymentType: 'full-payment',
  token: '',
  bookingList: null,
  bookingListFull: null,
  contractProcessing: false,
  processNotifications: false,
  loadingNotifications: false,
  bookingTab: 'summary',
  overlay: {
    status: false,
    type: '',
  },
})

const mutations: MutationTree<AccountState> = {
  /**
   * Reset the account details
   * @param state
   **/
  resetState(state: AccountState) {
    state.loginStatus = false
    state.user = null
    state.customer = null
    state.activeBooking = null
    state.activeProduct = null
    state.activePaymentOpts = null
    state.activePaymentType = 'full-payment'
    state.token = ''
    state.bookingList = null
    state.bookingListFull = null
    state.contractProcessing = false
    state.processNotifications = false
    state.loadingNotifications = false
    state.bookingTab = 'summary'
    state.overlay = {
      status: false,
      type: '',
    }
  },
  logout(state: AccountState) {
    state.user = null
    state.customer = null
    state.token = ''
    state.bookingList = null
    state.bookingTab = 'summary'
    state.overlay = { status: false, type: 'logout' }
  },
  setActiveBooking(state: AccountState, booking: BookingOutput) {
    state.activeBooking = booking
  },
  setActiveProduct(state: AccountState, product: ProviderProduct) {
    state.activeProduct = product
  },
  updateLoginStatus(state: AccountState, status: boolean) {
    state.loginStatus = status
  },
  updateUser(state: AccountState, user: User) {
    state.user = user
  },
  updateCustomer(state: AccountState, customer: Customer) {
    state.customer = customer
  },
  updateToken(state: AccountState, token: string) {
    state.token = token
  },
  updateBookingList(state: AccountState, bookingList: BookingOutput) {
    state.bookingList = bookingList
  },
  updateBookingListFull(state: AccountState, booking: BookingOutput) {
    if (state.bookingListFull) {
      const idx = state.bookingListFull!.findIndex((s) => s.id === booking.id)
      if (idx !== -1) {
        state.bookingListFull![idx] = booking
      } else {
        state.bookingListFull!.push(booking)
      }
    } else {
      state.bookingListFull = []
      state.bookingListFull!.push(booking)
    }
  },
  updateBookingPassenger(
    state: AccountState,
    { booking, passenger }: { booking: BookingOutput; passenger: Pax }
  ) {
    const idx = state.bookingListFull!.findIndex((s) => s.id === booking.id)
    if (idx !== -1) {
      const idy = state.bookingListFull![idx].passengers.findIndex(
        (p) => p.id === passenger.id
      )
      state.bookingListFull![idx].passengers[idy] = passenger
    }
  },
  updatePassengerFile(
    state: AccountState,
    {
      booking,
      passenger,
      file,
    }: { booking: BookingOutput; passenger: Pax; file: FileItem }
  ) {
    const idx = state.bookingListFull!.findIndex((b) => b.id === booking.id)
    if (idx !== -1) {
      const idy = state.bookingListFull![idx].passengers.findIndex(
        (p) => p.id === passenger.id
      )
      if (state.bookingListFull![idx].passengers![idy].files) {
        const idz = state.bookingListFull![idx].passengers![
          idy
        ].files!.findIndex((f) => f.title === file.title)
        if (idz !== -1) {
          state.bookingListFull![idx].passengers![idy].files![idz] = file
        } else {
          state.bookingListFull![idx].passengers![idy].files!.push(file)
        }
      } else {
        state.bookingListFull![idx].passengers![idy].files = []
        state.bookingListFull![idx].passengers![idy].files?.push(file)
      }
    }
  },
  updatePassengerNote(
    state: AccountState,
    {
      booking,
      passenger,
      note,
    }: { booking: BookingOutput; passenger: Pax; note: NoteItem }
  ) {
    const idx = state.bookingListFull!.findIndex((b) => b.id === booking.id)
    if (idx !== -1) {
      const idy = state.bookingListFull![idx].passengers.findIndex(
        (p) => p.id === passenger.id
      )
      if (state.bookingListFull![idx].passengers![idy].notes) {
        const idz = state.bookingListFull![idx].passengers![
          idy
        ].notes!.findIndex((n) => n.title === note.title)
        if (idz !== -1) {
          state.bookingListFull![idx].passengers![idy].notes![idz] = note
        } else {
          state.bookingListFull![idx].passengers![idy].notes!.push(note)
        }
      } else {
        state.bookingListFull![idx].passengers![idy].notes = []
        state.bookingListFull![idx].passengers![idy].notes!.push(note)
      }
    }
  },
  updateContractProcessing(state: AccountState, status: boolean) {
    state.contractProcessing = status
  },
  updateNotificationProcessing(state: AccountState, status: boolean) {
    state.processNotifications = status
  },
  updateNotificationLoading(state: AccountState, status: boolean) {
    state.loadingNotifications = status
  },
  updateBookingTab(state: AccountState, tab: string) {
    state.bookingTab = tab
  },
  updatePaymentType(state: AccountState, type: string) {
    state.activePaymentType = type
  },
  updateProductPaymentsOpts(state: AccountState, product: ProductPaymentOpts) {
    state.activePaymentOpts = []
    const idx = state.activePaymentOpts!.findIndex((p) => p.id === product.id)
    if (idx !== -1) {
      state.activePaymentOpts![idx] = product
    } else {
      state.activePaymentOpts!.push(product)
    }
  },
  updateOverlay(
    state: AccountState,
    {
      overlayStatus,
      overlayType,
    }: { overlayStatus: boolean; overlayType: string }
  ) {
    state.overlay = { status: overlayStatus, type: overlayType }
  },
  resetPaymentType(state: AccountState) {
    state.activePaymentType = 'full-payment'
  },
  clearContractProcessing(state: AccountState) {
    state.contractProcessing = false
  },
  clearNotificationProcessing(state: AccountState) {
    state.processNotifications = false
  },
  clearNotificationLoading(state: AccountState) {
    state.loadingNotifications = false
  },
}

const actions = {
  async accountBookingList(
    { commit }: { commit: Function },
    {
      config: { apiHost },
      token,
      reload,
    }: {
      config: NuxtRuntimeConfig
      token: string | null
      reload: boolean | false
    }
  ) {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    const path = apiHost + '/account/bookings'
    await axios
      .get(path, { headers })
      .then((response) => {
        commit('updateBookingList', response.data)
        commit('updateNotificationLoading', true)
        const confirmedData =
          response.data?.filter((item: any) => item.status === 'CONFIRMED') ||
          []
        const fetchData = async () => {
          const data = entries(confirmedData)
          const fetchItem = async ([index, value]: [
            index: any,
            value: any
          ]) => {
            const resp = await axios
              .get(path + '/' + value.id, { headers })
              .catch((e: any) => {
                console.log(e)
              })
            if (resp) {
              commit('updateBookingListFull', resp.data)
              if (parseInt(index) === confirmedData?.length - 1 && reload) {
                commit('updateContractProcessing', true)
                commit('clearNotificationLoading')
                setTimeout(() => {
                  commit('clearContractProcessing')
                }, 100)
              }
              if (parseInt(index) === confirmedData?.length - 1) {
                setTimeout(() => {
                  commit('updateNotificationProcessing', true)
                  setTimeout(() => {
                    commit('clearNotificationProcessing')
                    commit('clearNotificationLoading')
                  }, 100)
                }, 2000)
              }
            }
          }

          await pMap(data, fetchItem, { concurrency: 5 })
        }
        fetchData()
      })
      .catch((e: any) => {
        console.log(e)
      })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
